export default {
  LOCALE: {
    ISO: {
      "639-1": "sv",
      "3166-1": "SE",
      IETF: "sv-SE",
    },
    DIR: "ltr",
    SHORT: "SE",
    LONG: "sv-SE",
    LONG_UNDERSCORE: "sv_SE",
    CURRENCY: "SEK",
    CURRENCY_SHORT: {
      SEK: " kr",
    },
    FALLBACK_CURRENCY: " kr",
  },
  HEAD: {
    TITLE_TEMPLATE: ":s | Grossist, Distributör & Leverantör",
    DEFAULT_TITLE: "Direkt från producent. Nästan. | Grossist, Distributör & Leverantör",
    DEFAULT_SITE_NAME: "Direkt från producent. Nästan. | Grossist, Distributör & Leverantör",
    DESCRIPTION:
      "Oberoende rikstäckande e-handelsgrossist av storköksartiklar för café, restaurang, hotell, kontor och andra verksamheter.",
  },
  LOGO: {
    ALT: "Outofhome logotyp",
    TITLE: "Outofhome",
    HREF: "https://www.outofhome.se",
  },
  COOKIE_DIALOGUE: {
    PRIMARY_ACTION: "Acceptera",
    SECONDARY_ACTION: "Avvisa",
    DESCRIPTION:
      "Vi använder oss av egna och tredje-parts kakor för att personalisera innehåll och analysera webbtrafik.",
    READ_MORE_LINK: "/anvandarvillkor#cookies",
    READ_MORE_TEXT: "Läs mer om våra kakor.",
  },
  SEARCH: {
    PLACEHOLDER: "Vad letar du efter?",
    SUBMIT: "GO!",
    NO_RESULTS: 'Sökningen på "%search" gav inga resultat',
  },
  SPLASH: {
    TEXT: "Handla direkt från <br /> <span>Santa Maria.</span><br />Nästan.",
    CTA: "Vadå nästan?",

    ABOUT_US: "Om oss",
    FOODSERVICE: "Nyheter",
    BRANDS: "Varumärken",
    CONTACT: "Kundservice",

    SHOP_DIRECTLY_FROM: "Handla direkt från",
    ALMOST: "Nästan",
  },
  FRONTPAGE: {
    TITLE: "Direkt från producent. Nästan.",
    DESCRIPTION:
      "Oberoende rikstäckande e-handelsgrossist av storköksartiklar för café, restaurang, hotell, kontor och andra verksamheter.",
    REGISTER: "Skapa konto",
    LOGIN: "Logga in",
    FORGOT_PASSWORD: "Klicka här om du glömt lösenordet",
    ABOUT_US_TEASER: "Enklaste sättet för företag att handla mat och dryck",
    ABOUT_US:
      "Vi tar producenter och kunder närmare varandra. Vi tror att raka rör och öppen handel skapar en rättvis marknad med värde för alla. Egentligen flyttar vi bara varor från oss till er. Oftast är det livsmedel. Vissa kallar oss matgrossist.",
    VENDORS_TITLE: "Våra leverantörer",
    VENDORS_TEXT_1_A: "Hos oss får producenten ge bra villkor till dig som kund utan vår inblandning. Handla ",
    VENDORS_TEXT_1_B: " lagerförda artiklar från ",
    VENDORS_TEXT_1_C: " varumärken, men håll utkik – vi uppdaterar sortimentet varje dag.",
    VENDORS_TEXT_1_D:
      "Undvik anbudsträsket, bonusar, kickbacks och sponsrade förkläden – få rätt pris direkt istället. Det är ju ändå du som betalar i slutändan.",
    VENDORS_TEXT_2:
      "Är du intresserad av att bli leverantör hos outofhome? Kul! Här kan du läsa mer om hur du går till väga.",
    VENDORS_SEE_ALL: "Se alla varumärken",
    VENDORS_CTA: "Bli leverantör",
    TESTIMONIALS_TITLE: "Du är i gott sällskap.",
    TESTIMONIALS_TITLE_SPECIFIC: "Vad säger andra %segment som handlar idag?",
    NEWS_TITLE: "Nyheter",
    NEWS_CTA: "Läs hela nyheten",
    CATEGORIES_CTA: "Visa alla produkttyper",
    ABOUT_YOU_TITLE: "Välj det som passar bäst så berättar vi mer.",
    ABOUT_YOU_TEXT:
      "Våra kunder börjar bli många och olika och sortimentet växer för varje dag som går i takt med att fler varumärken och produkter efterfrågas. Fortsätt nedåt så skall vi berätta vad vi erbjuder för just din verksamhet.",
    ABOUT_YOU_SUB_TITLE: "Vad passar bäst in på er?",
    RECOMMENDED_PRODUCTS_TITLE: "Mest köpta produkter av %segment",

    CUSTOMER_SEGMENT_LEFT_A: "Du är i gott sällskap. Idag handlar ",
    CUSTOMER_SEGMENT_LEFT_B: " olika artiklar från oss och varje dag blir det fler.",

    // restaurang
    CUSTOMER_SEGMENT_LEFT_1: " restauranger ",
    CUSTOMER_SEGMENT_RIGHT_1_TITLE: "Rikstäckande restauranggrossist",
    CUSTOMER_SEGMENT_RIGHT_1_TEXT:
      "Kryddor, fond, buljong, konserver och råvaror – vi lagerför det mesta en restaurang behöver och vårt sortiment med storköksprodukter och varumärken växer varje dag. Om du vet vad du vill ha och hellre vill ha bra kvalitet än dyra inspirationsfoldrar är vi ett bra alternativ för dig.",
    // cafe
    CUSTOMER_SEGMENT_LEFT_2: " caféer ",
    CUSTOMER_SEGMENT_RIGHT_2_TITLE: "Matgrossist för café",
    CUSTOMER_SEGMENT_RIGHT_2_TEXT:
      "Kalla drycker, godsaker, råvaror för mat, förbrukningsvaror och så klart kaffe och te. Outofhome.se gör det enkelt för dig att fixa ett brett sortiment med starka varumärken och produkter till ditt café. Tack vare vår effektiva e-handel kan vi lämna ett bra pris på hela vårt sortiment, vilket gör att du kan ge dina kunder högre kvalitet till en lägre kostnad.",
    // hotell & konferens
    CUSTOMER_SEGMENT_LEFT_4: " konferensanläggningar och hotell ",
    CUSTOMER_SEGMENT_RIGHT_4_TITLE: "Allt för hotellet och konferensen",
    CUSTOMER_SEGMENT_RIGHT_4_TEXT:
      "Driver du ett hotell? Kul! Vi har jättemånga produkter på lager för just hotell. Storköksartiklar för restaurangen? Check. Snacks, godis, te och kaffe till konferensen? Check. Förbrukningsmaterial för rengöring- och städ? Check på den med. Vi har skapat den här butiken just för sådana som dig. En plats där du hittar varor av god kvalitet, till ett ännu godare pris.",
    // gym/träning/förening
    CUSTOMER_SEGMENT_LEFT_6: " föreningar ",
    CUSTOMER_SEGMENT_RIGHT_6_TITLE: "Gym, träningsanläggning eller förening",
    CUSTOMER_SEGMENT_RIGHT_6_TEXT:
      "För ett tag sedan när vi öppnade den här butiken tänkte vi mest på hotell, restauranger och caféer. Det visade sig dock att en hel del gym, träningsanläggningar och föreningar tyckte om samma produkter. Väldigt kul, tycker vi! Nu har vi tagit in ännu fler produkter för just träning. Prova oss och se om det passar även för er!",
    // dagligvaruhandel
    CUSTOMER_SEGMENT_LEFT_7: " livsmedelsbutiker ",
    CUSTOMER_SEGMENT_RIGHT_7_TITLE: "Grossist för livsmedelsbutiker",
    CUSTOMER_SEGMENT_RIGHT_7_TEXT:
      "Trött på att det bara finns 1 val av grossist i Sverige? Du är inte ensam. Allt fler livsmedelsbutiker – från de små lokala till de större kedjorna – upptäcker fördelarna med att handla hos Out of Home. Vi har det mesta som behövs för att driva en framgångsrik livsmedelsbutik – toalettpapper, mjölk, kaffe, frysta blåbär, den senaste NOCCO-smaken, kexchoklad, med mera.",
    // kontor/arbetsplats
    CUSTOMER_SEGMENT_LEFT_8: " arbetsplatser ",
    CUSTOMER_SEGMENT_RIGHT_8_TITLE: "E-handelsgrossist för kontor och arbetsplatser",
    CUSTOMER_SEGMENT_RIGHT_8_TEXT:
      "Vi är en arbetsplats. Du är en arbetsplats. Eller nja, du jobbar på en arbetsplats. Kanske är det likt vårt kontor. Vi dricker kaffe eller te, skriver ut papper, lägger papper i pärmar, går på toa. Ungefär i den ordningen. Behöver ni också beställa saker till kontoret för att fortsätta hålla på med ovan aktiviteter är outofhome.se ett perfekt val. Inga konstigheter, bara rätt produkter till ett rimligt pris och en snabb leverans.",
    // produktion
    CUSTOMER_SEGMENT_LEFT_9: " livsmedelsproducenter ",
    CUSTOMER_SEGMENT_RIGHT_9_TITLE: "Råvaror och förbrukning till livsmedelsproduktion",
    CUSTOMER_SEGMENT_RIGHT_9_TEXT:
      "Du producerar massor med mat. Kanske för dagligvaruhandeln eller annan återförsäljare. Vårt lager är fyllt med råvaror för just detta ändamål. Jättestora förpackningar av kryddor, fond, quinoa eller liknande. Vi har även allt som behövs för rengöring och hygien. Letar du efter en leverantör med bra sortiment och schyssta priser, då har du hittat rätt.",
    // offentlig verksamhet
    CUSTOMER_SEGMENT_LEFT_10: " offentliga verksamheter ",
    CUSTOMER_SEGMENT_RIGHT_10_TITLE: "Matgrossist för offentlig verksamhet",
    CUSTOMER_SEGMENT_RIGHT_10_TEXT:
      "Vi är en ny rikstäckande grossist och kan i teorin svara på alla anbud till offentliga upphandlingar. Problemet är att vi inte är så många. Vi har inte en avdelning för att fylla i alla dokument. Sanningen är att vi bara är ett fåtal personer. Det gör ju visserligen att om vi skulle fylla i alla dokument så kan vi ge bra priser. Kontakta gärna oss om du vill du att vi svarar på just ditt anbud.",
    // servicehandel
    CUSTOMER_SEGMENT_LEFT_11: " servicebutiker ",
    CUSTOMER_SEGMENT_RIGHT_11_TITLE: "Grossist för kiosk och servicehandel",
    CUSTOMER_SEGMENT_RIGHT_11_TEXT:
      "Är du trött på att det bara finns typ ett alternativ att handla sina varor från som kiosk? Du verkar inte vara ensam. Hundratals servicebutiker (kiosker, bensinmackar, med flera) har de senaste åren börjat handla inköp från Out of Home. Vi verkar ha mycket av de varorna som behövs för att driva en grym kiosk eller servicebutik. Från stycksakerna, godiset till mjölk, gräddfil och sånt folk glömt handla som de springer ner för. Vi håller det enkelt - bra varor, snabbt levererade, till ett bra pris.",
    // detaljhandel
    CUSTOMER_SEGMENT_LEFT_12: " detaljhandelsbutiker ",
    CUSTOMER_SEGMENT_RIGHT_12_TITLE: "Allt till din kassalinje",
    CUSTOMER_SEGMENT_RIGHT_12_TEXT:
      "Försöker du också få alla dina kunder att handla lite extra saker när de är på väg ut mot kassan? Det gör vi också. Du kommer att märka när du handlar av oss att vi genom köpresan föreslår produkter vi tror att du är sugen på. Att gå och handla när man är hungrig kan både vara det bästa och sämsta man kan göra. Aldrig har en kexchoklad smakat så gott som när man precis köpt en skruvdragare, lite kopieringspapper eller en cementblandare. Det är på något sätt en skälig belöning för att man gjort ett bra köp.",
    // grossist
    CUSTOMER_SEGMENT_LEFT_13: " grossister ",
    CUSTOMER_SEGMENT_RIGHT_13_TITLE: "Logistikpartner för grossister",
    CUSTOMER_SEGMENT_RIGHT_13_TEXT:
      "Vi fungerar som en förlängd arm för grossister som behöver effektiva lösningar för att fylla sina lager. Vi samarbetar med Sveriges största livsmedelsproducenter för att säkerställa en smidig varuförsörjning. Genom vårt centrallager och effektiva logistiksystem kan vi snabbt och pålitligt leverera varor för att komplettera ditt sortiment eller fylla på dina lager.",
  },
  ABOUT_US: {
    TITLE: "Om oss",
    HEADING: "Handla direkt från producent. Nästan.",
    DESCRIPTION:
      "Vi tar producenter och kunder närmare varandra. Vi tror att raka rör och öppen handel skapar en rättvis marknad med värde för alla. Egentligen flyttar vi bara varor från oss till er. Oftast är det livsmedel. Vissa kallar oss matgrossist.",
    FRONTPAGE: "Startsida",
    BECOME_SUPPLIER: "Bli leverantör",
    EMPLOYEES: "Anställda",
    BRANDS: "Varumärken",
  },
  CUSTOMERS_VIEW: {
    TITLE: "Vem du än är, vi är här för att leverera.",
    DESCRIPTION:
      "Kan vi leverera bra produkter till rimliga priser spelar det ingen roll vad det står på dörren eller vem du känner. Vi levererar till att från gatukök till michelinkrogar, livsmedelsbutiker, advokatbyråer, frisörer, fotbollsklubbar, gym och bilverkstäder.",
    TEXT: "När vi startade Out of Home trodde var tanken att leverera till restauranger och caféer. Väldigt snabbt började en massa andra typer av företag handla och nu är det en salig blandning. Från gatukök till mischelinkrogar, livsmedelsbutiker, advokatbyråer, frisörer, fotbollsklubbar, gym och bilverkstäder. Kul tycker vi! Kan vi leverera bra produkter till rimliga priser spelar det ingen roll vad det står på dörren eller vem du känner.",
  },
  EMPLOYEES: {
    TITLE: "Träffa gänget",
    DESCRIPTION:
      "Här är alla vi som jobbar på outofhome.se. Vi har ett litet kontor i Gamlestaden i Göteborg där du gärna får hälsa på oss. Vill du komma i kontakt med någon speciell är det bara att ringa eller maila.",
    INFO: "Här är alla vi som jobbar på outofhome.se. Vi har ett litet kontor i Gamlestaden i Göteborg där du gärna får hälsa på oss. Vill du komma i kontakt med någon speciell är det bara att ringa eller maila. Du kan även ",
    CHAT_WITH_US: "chatta med oss",
  },
  CUSTOMER_SERVICE: {
    TITLE: "Kundservice",
    TITLE_2: "Vi som svarar i kundtjänst",
    INFO: "Har du några som helst frågor är det bara att kontakta oss så svarar vi så snabbt vi kan!",
    CHAT_WITH_US: "Chatta med oss",
    DESCRIPTION:
      "Har du frågor om din leverans, en faktura eller kanske bara glömt ditt lösenord? Vår kundserviceavdelning hjälper dig gärna.",
  },
  ADDRESS_REMIND: {
    TITLE: "Fyll i/kontrollera leveransinstruktion",
    INFO: "Nyligen bytte vi transportör till Schenker och de kollar särskilt noggrant på fältet leveransinstruktion (tidigare vår Adressrad 2). Vi ber dig därför kontrollera och/eller fylla i detta fält efter bästa förmåga för att chauffören skall hitta till dig. Tack på förhand! /Outofhome-teamet",
    STREET_2: "Leveransinstruktion och särskilda öppettider",
  },
  NAG: {
    TEXT: "De senaste 12 månaderna har 3 292 företag valt att skapa konto hos oss.",
    PAYOFF: "Det tar 3 minuter och är kostnadsfritt.<br/>Btw. du får välja ett bättre pris också.",
    CONTACT_US: "Kontakta oss",
    REGISTER: "Bli kund",
  },
  MY_FRONTPAGE: {
    TITLE: "Min startsida",
  },
  FORGOT_PASSWORD: {
    TITLE: "Återställ lösenord",
    SEND: "Skicka",
  },
  NEWS: {
    DEFAULT_TITLE: "Nyheter om Outofhome",
    TAG_TITLE: "Nyheter om %tag",
    NEWS: "Nyheter",
    MORE: "Fler nyheter",
  },
  FOOTER: {
    CUSTOMER_SERVICE: "Kundservice",
    CUSTOMER_SERVICE_TEXT:
      "Du hittar svaret på några av de vanligaste frågorna och kontaktmöjligheter till oss via knappen här nedanför.",
    CUSTOMER_SERVICE_TEXT_LOGGED_IN:
      "I din %url kan du se leveransstatus på alla dina beställningar. Har du en fråga? Kontakta oss via mail, telefon eller chat.",
    TELEPHONE: "031-700 83 82\nHelgfria vardagar: 9-12, 14-15",
    TERMS_AND_CONDITIONS: "Köp- och leveransvillkor",
    TERMS_AND_CONDITIONS_TEXT:
      "Beställningar före lunch skickas samma dag och levereras normalt dagen efter, om du befinner dig mellan Ystad och Uppsala. Vi tar väl hand om dina personuppgifter och tar din personliga integritet på största allvar.",
    TERMS_LINK: "Läs våra användarvillkor",
    PRIVACY_LINK: "Läs vår sekretesspolicy",
    COPYRIGHT: "© %year OUT OF HOME AB. ALLA RÄTTIGHETER FÖRBEHÅLLES.",
    CONTACT_EMAIL: "kundservice@outofhome.se",
    ADDRESS: "OUT OF HOME AB<br />LAGERGATAN 1<br />415 11 GÖTEBORG<br />ORG. NUMMER: 556614-6584",
    CHAT_WITH_US: "Chatta med oss",
    READ_MORE: "Läs mer",
  },
  PRE_HEADER: {
    TEXT_LEFT_ROUTE: "/om-oss",
    TEXT_LEFT_LINK_TEXT: "utmanar livsmedelsbranschen",
    TEXT_LEFT_TEXT: "15 610 kunder",

    TEXT_MIDDLE_ROUTE: "/kunder",
    TEXT_MIDDLE_LINK_TEXT: "1379 omdömen",
    TEXT_MIDDLE_TEXT: "4,67 av 5 från",

    TEXT_RIGHT_ROUTE: "/valj-ditt-pris",
    TEXT_RIGHT_LINK_TEXT: "Välj ditt pris",
    TEXT_RIGHT_TEXT: "helt utan avtal",

    TEXT_RIGHT_ROUTE_LOGGED_IN: "/p",
    TEXT_RIGHT_LINK_TEXT_LOGGED_IN: "artiklar i lagret",
    TEXT_RIGHT_TEXT_LOGGED_IN: "4 594",
  },
  NAV: {
    MY_FRONTPAGE: "Min startsida",
    WISHLIST: "Mina produkter",
    PRODUCTS: "Alla produkter",
    ORDER_HISTORY: "Orderhistorik",
    MY_ACCOUNT: "Mitt konto",
    MY_DISCOUNTS: "Mina kampanjer",
    LOGIN: "Logga in",
    REGISTER: "Skapa konto",
    LOGOUT: "Logga ut",
    CATEGORIES: "Kategorier",
    BRANDS: "Varumärken",
    DRINKS: "Dryck",
    EATABLES: "Matvaror",
    SNACKS: "Snacks",
    ACCESSORIES: "Tillbehör",
    CUSTOMER_TYPE: "Per kundtyp",
    NEWS: "Nyheter",
    QUICK_LINKS: "Snabblänkar",
    CUSTOMER_SERVICE: "Kundservice",
    ABOUT_US: "Om oss",
    QUICK_LINKS_ITEMS: {
      CAMPAIGNS: "Kampanjer",
      NEWS: "Nyheter",
      POPULAR: "Topplista %type",
    },
  },
  BRAND: {
    TITLE: "Varumärken och leverantörer",
    HEADING: "Vi älskar bra varumärken",
    SECTION_1:
      "<strong>Den moderna handeln genomgår en förändring.</strong> E-handeln växer varje år och allt fler företag har insett fördelarna med att beställa varor på nätet. Den traditionella grossist-branschen bygger på långa avtal, telefonförsäljning och fältverksamma säljare. Outofhome.se bygger på övertygelsen om att e-handel som effektivt distributionsverktyg ger bättre villkor och mer marknadskraftiga priser till dig som kund.",
    SECTION_2:
      "<strong>Vi väljer varumärken med omsorg.</strong> Hos oss hittar du därför ett urval av riktigt bra producenter som tänker på både människan, produkten och miljön. Det är inte konstigt att de samtidigt är välkända och stora inom sitt produktsegment. Vi är fortfarande i en uppstartsfas och tar hela tiden in nya spännande varumärken och produkter. Vi är otroligt stolta över att idag kunna få vara grossist för följande starka varumärken:",
  },
  PRODUCT: {
    TITLE: "%name från %brand | %product_type",
    SUPPLIER: "Leverantör",
    BRAND: "Varumärke",
    SKU: "Lev. Art. Nr.",
    OUR_SKU: "Vårt Art. Nr.",
    SALES_UNIT: "Försäljningsenhet",
    QTY_IN_PACKAGE: "Antal st per krt",
    PACKAGES_IN_PALLET: "Antal krt per pall",
    PCS: "st",
    DABAS: "Produktinfo",
    LINK: "Till Dabas/Leverantör",
    CHANGE_IMAGE: "Byt bild",

    OFFER_TITLE: "Handlar du mycket?",
    OFFER_TEXT:
      "För återkommande och större köp kan vi erbjuda mängdrabatt. Kontakta oss med detta formulär för att anmäla ditt intresse.",
    OFFER_SEND: "Skicka",

    SIMILAR_PRODUCTS_HEADING: "Liknande produkter",
    RELATED_PRODUCTS_HEADING: "Relaterade produkter",
    RECOMMENDED_PRODUCTS_HEADING: "Andra som köpt har även handlat",

    ADD_FAVORITE: "Lägg till favorit",
    REMOVE_FAVORITE: "Ta bort favorit",
    RECOMMENDED: "Rekommenderad",
    ABOUT: "Fler produkter från",

    CAMPAIGN: "Kampanj",
    NEWS: "Nyhet",
    NEWS__SHORT: "NY",
    DISCOUNT: "-%discount%",
    CONTRACTED: "Avtalad produkt",
    CONTRACTED__SHORT: "A",

    FROZEN: "Fryst",
    COLD: "Kyld",
    ALCOHOL: "Alkohol",
    EAN_PCS: "EAN ST",
    EAN_BOX: "EAN KRT",
  },
  BRING: {
    AT_LUNCH: ".",
    COST: "En kostnad på <b>%price</b> tillkommer på grund av kort leveransfönster.",
    NO_COST: "Ingen kostnad tillkommer.",
    NO_DELIVERY_WINDOWS: "Inga tider",
    NOT_AT_LUNCH: ", ej lunchtid.",
    POSTCODE_MISSING: "Fyll i postnummer",
    STEP_1_HEADING: "1. Välj dag",
    STEP_2_HEADING: "2. Välj leveransfönster",
    STEP_3_HEADING: "3. Summan av kardemumman",
    SUMMARY: "Din leverans sker mellan <b>%from</b> och <b>%to</b> på %date",
  },
  CHECKOUT: {
    PAY_WITH_CARD: "Betala",
    CARD_PAYMENT: "Betala",
    EDIT: "Ändra",
    PREFERRED_DELIVERY_DATE: "2. Önskad leveransdag",
  },
  CART: {
    CHECKOUT: "Kassa",
    ORDER_OVERVIEW: "Varukorg",
    SELECT_PAYMENT_METHOD: "Välj betalsätt",
    FREE_SHIPPING_KOLONIAL: "%amount till fri frakt",
    FREE_SHIPPING_KOLONIAL__FULL: "Fri frakt",
    FREE_SHIPPING_FROZEN: "Fri frakt fryst (%amount)",
    FREE_SHIPPING_FROZEN__FULL: "Fri frakt fryst",
    FREE_SHIPPING: "Fri frakt",
    QUANTITY_DISCOUNT: "%amount till mängdrabatt (%discountPercent%)",
    QUANTITY_DISCOUNT__FULL: "%amount till mängdrabatt (%discountPercent%)",
    DISCOUNT_PERCENT: "Rabatt (%percent%)",
    OPEN_DRAWER: "Visa översikt",
    CLOSE_DRAWER: "Stäng översikt",
    TOTAL: "Summa",
    QTY: "Antal",
    PAYMENT_METHOD: "Betalsätt",
    SELECTED_SHIPPING: "Förvald address",
    POSTPONEMENT_ORDER: "Senarelägg order",
    DIFFERENT_ADDRESS: "Faktura till annan adress",
    DETAILED_INFO: "Visa moms",
    SUBTOTAL: "Delsumma",
    DISCOUNT: "Rabatt",
    DEPOSIT: "Pant",
    TOTAL_EXCL_TAX: "Totalt (exkl. moms)",
    TOTAL_INCL_TAX: "Totalt (inkl. moms)",
    NEXT_DELIVERY: "Nästa deadline för utleverans från lagret",
    SUBMIT: "Lägg order",
    QUICK_BUY: "Snabbköp",
    OPEN_DRAWER: "Öppna kassa",
    CLOSE_DRAWER: "Stäng",
    CANCEL: "Tillbaka",
    CONFIRM_PURCHASE: "Bekräfta order",
    CANCEL: "Avbryt",
    NEW_ADDRESS: "Ny adress",
    SAVE_ADDRESS: "Spara denna adress",

    USE_REFERENCE: "Använd referens",
    REFERENCE: "Referens",
    PLUS_SHIPPING: "+frakt",

    COUPON_CODE: "Kampanjkod",
    COUPON_ACTIVATE: "Aktivera",
    COUPON_DEACTIVATE: "Ta bort",

    NOT_ENOUGH_IN_STOCK: "Valt antal av produkten finns inte i lager",

    CREATE_ACCOUNT: "Skapa konto (frivilligt)",

    //temp
    TAX: "Moms",
    NEWS: "Nyhet",
  },
  TERMS: {
    I_ACCEPT: "Jag accepterar",
    THE_TERMS: "villkoren",
  },
  PAYMENT: {
    HEADING: "Betalsätt",
    COLLECTOR: {
      SSN: "Organisationsnummer",
      SUBMIT: "Hämta adress",
      INFO: "Företagsnamn måste stämma med org. nummer för att Walley skall godkänna beställningen.",
    },
  },
  SHIPPING: {
    INFO: "All transport klimatkompenseras genom FN-certifierade projekt som minskar CO2 utsläpp motsvarande din transport.",
    INFO_LINK: "Så här går det till",
    INFO_URL: "/nyheter/klimatkompenserad-som-foretag",
    STOCK_OVERFLOW:
      "En eller flera produkter i din varukorg behöver fyllas på. Datumet är beräknat från när vi estimerar att ordern kan skickas i sin helhet samt vilka leveransdagar ditt postnummer har.",
    STOCK_NO_POSTCODE:
      "Saknar information om leveransdagar till ditt postnummer. Vi kan inte garantera leveransdagen men vi kommer boka sändningen med den önskade leveransdagen du valt.",
    STOCK_NO_TIMETABLE:
      "Ditt postnummer trafikeras inte i ordinarie distribution. Vi kan inte garantera leveransdagen men vi kommer boka sändningen med den önskade leveransdagen du valt.",
    STOCK_BELOW_WEIGHT:
      "Vi kan inte garantera leveransdagen men vi kommer boka sändningen med den önskade leveransdagen du valt.",
    IN_STOCK_BEFORE_12:
      "Datumet är beräknat på att ordern kan skickas idag, samt ledtid och leveransdagar till ditt postnummer.",
    IN_STOCK_AFTER_12:
      "Datumet är beräknat på att ordern kan skickas imorgon, samt ledtid och leveransdagar till ditt postnummer.",

    STOCK_POPUP_INFO:
      "Vi beräknar att din fullständiga order levereras tidigast <b>%deliveryDate</b> baserat på ledtid och leveransdagar på ditt postnummer. Dessa datum kan ej garanteras då inleveranser kan bli försenade från producent.",
    STOCK_POPUP_ALL_ITEMS_IN_STOCK:
      "Nu finns allt i lager för din order. Notera att beräknad leveransdag har ändrats och dubbelkolla övrig information innan du bekräftar order. Följande justeringar har gjorts på artiklar som hade lågt lagersaldo:",
    STOCK_POPUP_CLOSE: "Gå vidare",
    STOCK_POPUP_DELIVERY_IMMEDIATELY: "Behöver leverans direkt",
  },
  PREFERRED_DELIVERY_DATE: {
    HEADING: "Leveransalternativ",
  },
  CROSSELL: {
    HEADING: "Produktförslag",
    INFO: "Baserat på din kundtyp och varukorg",
    MORE_SUGGESTIONS: "Visa fler förslag",
  },
  ORDER: {
    TITLE: "Order",
    NO_ORDERS_FOUND: "Du har ännu inte lagt några beställningar. ",
    TRACK: "Spåra försändelse %index",
    SHIPPING: "Leverans",
    INVOICE: "Faktura",
    DOWNLOAD_INVOICE: "Ladda ner faktura",
    DOWNLOAD: "Ladda ner faktura",
    DOWNLOAD_STRIPE: "Fakturaunderlag",
    DOWNLOAD_XLSX: "Ladda ner xlsx",
    REORDER: "Lägg order igen",
    ITEMS_NOT_MATCHING: "Alla produkter fanns inte i lager",
    ORDER_INFO: "Orderinfo",
    PANT: "Pant",
    SUBTOTAL: "Delsumma",
    TOTAL: "Summa",
    TOTAL_EXCL_TAX: "Totalt (exkl. moms)",
    DATE: "Datum",
    ORDER_NUMBER: "Ordernummer",
    STATUS_processing: "Behandlas",
    STATUS_payment_review: "Behandlas",
    STATUS_complete: "Skickad",
    STATUS_canceled: "Annullerad",
    SHIPMENT: "Leverans",
    ORDERED_PRODUCTS: "Beställda artiklar",
  },

  VISMA: {
    NEW: "Behandlas",
    PROCESSING: "Behandlas",
    DELAYED: "Inväntar inleverans",
    PICKED: "Plockad",
    SHIPPED: "Skickad",
    DELIVERED: "Levererad",
    PAID: "Betald",
    PAID_WITH_COLLECTOR: "Via Walley",
    INVOICED: "Ej betald",
    OVERDUE: "Förfallen",
    CANCELED: "Avbruten",
  },
  OVERDUE_INVOICE: {
    DIALOGUE_TITLE: "Förfallen faktura",
    DIALOGUE_DESCRIPTION:
      "Vi upptäckte precis att ni har minst en obetald faktura. Om fakturan inte kommit fram som den skulle går den att ladda ner här eller från din orderhistorik. Vår policy är att inte skicka iväg leveranser förrän detta reglerats. Behöver du ha din leverans omgående vänligen maila inbetalningskvitto till <a href='mailto:ekonomi@outofhome.se'>ekonomi@outofhome.se</a>.",
    DIALOGUE_LISTITEM: "<b>Faktura %invoiceId </b>- förföll %dueDate",
    GRAND_TOTAL: "Förfallen totalsumma:",
  },
  SHIPMENT: {
    PROCESSING: "Behandlas",
    DELAYED: "Inväntar inleverans",
    PICKED: "Plockad",
    DELIVERED: "Levererad",
    SHIPPED: "Skickad",
    CANCELED: "Avbruten",
    BLOCKED: "Spärrad",
  },
  ADDIMOTION: {
    Packed: "Packad",
    Arrived: "Kommit fram",
    "En route": "På väg",
    Delivered: "Levererad",
    CONTACT_CARRIER: "Visa leveransstatus",
    LOADING: "Laddar...",
  },
  404: {
    TITLE: "Sidan hittades inte",
    HEADING: "Hoppsan!",
    TEXT: "Vi har letat bland tomater, kryddor och sparris. Den här sidan hittar vi helt enkelt inte!<br />Gå tillbaka till startsidan eller sök produkt till vänster om vår logga.",
    BUTTON: "Gå till start",
  },
  ERROR_PAGE: {
    TITLE: "Ett fel uppstod",
    CONTACT:
      'Vänligen försök igen senare. Kontakta gärna vår kundtjänst på <a href="mailto: kundservice@outofhome.se">kundservice@outofhome.se</a>.',
    BACK: "Tillbaka till startsidan",
  },
  SERVER_ERROR: {
    TITLE: "Ett fel uppstod",
    TEXT: 'Vänligen försök igen senare. Kontakta gärna vår kundtjänst på <a href="mailto: kundservice@outofhome.se">kundservice@outofhome.se</a>.',
  },
  SUCCESS: {
    TITLE: "Din order är nu lagd!",
    STATUS_HEADING: "Din order är lagd!",
    STATUS_HEADING_OVERDUE: "Din order är lagd men...<br/>har spärrats pga förfallen faktura",
    STATUS_TEXT: "Ditt ordernummer är %number",

    STEP_1: "En orderbekräftelse har skickats till %email. Där hittar du en sammanfattning av din beställning.",
    STEP_1_TITLE: "Beställningen bekräftas via e-mail",
    STEP_1_TITLE_OVERDUE_INVOICE: "Ladda ner och betala förfallen faktura",
    STEP_1_OVERDUE:
      "Om den här ordern är betald enligt ert system och något verkar fel hos oss. Vänligen <a href='mailto:ekonomi@outofhome.se'>maila oss</a> en skärmdump på betalningen.",
    STEP_2:
      "Vårt lager börjar inom kort att packa din beställning. Större beställningar går på halvpall eller helpall medan mindre går i kartong.",
    STEP_2_TITLE: "Bearbetning",
    STEP_2_OVERDUE_INVOICE:
      "Detta tar normalt 2-3 bankdagar. Är det bråttom - skicka bankbekräftelse på inbetalning till <a href='mailto:ekonomi@outofhome.se'>ekonomi@outofhome.se</a> så släpper vi ordern direkt.",
    STEP_2_TITLE_OVERDUE_INVOICE: "Vid inbetalning släpps din order",
    STEP_3: "Så fort ordern är plockad och klar ordnar vi lämplig transport till din adress.",
    STEP_3_TITLE: "Transport",
    STEP_4:
      "Så fort din beställning lämnat vårt lager får du en leveransbekräftelse. Den innehåller bl.a. information om hur du kan följa transporten.",
    STEP_4_TITLE: "Leverans",

    QUESTIONS_TITLE: "Frågor?",
    QUESTIONS_TEXT:
      "Har du frågor eller vill ändra din beställning har du en liten stund på dig att kontakta oss innan leveransen börjat packas. Ring oss på 031-700 83 82, maila kundservice@outofhome.se alternativt chatta via ikonen till höger.",
    QUESTIONS_CTA:
      "Sammanfattad information kring dina beställningar hittar du under din orderhistorik där du även kan följa transporten hela vägen till din dörr.",

    INVOICE_WARNING_HEADING: "Enligt vårt system har ni en eller flera förfallna fakturor",
    INVOICE_WARNING:
      'Er order kommer därför inte kunna skickas förrän betalningen registrerats. För att påskynda leveransen kan ni maila kvitto på inbetalningen till <a class="link" href="mailto: %email">ekonomi@outofhome.se</a>. Se <a class="link" href="%orderHistory">orderhistorik</a> eller <span class="link">kontakta oss</span> för mer information. Ladda ner fakturakopia här:',

    INVOICE_WARNING_HEADING_2: "Stämmer våra faktureringsuppgifter?",
    INVOICE_WARNING_2:
      "Fakturan skickades till din angivna adress eller e-post. Kontakta oss om du inte mottagit fakturan så att vi kan korrigera uppgifterna. Får du fakturan via snigelpost idag? Kontakta oss med e-postadress så skickar vi den elektroniskt i framtiden.",
  },
  REVIEW: {
    TITLE: "Vad tycker du om Out of home?",
    SUBMIT: "Skicka",
    SUBMITTED: "Tack för dina tankar!",
    SOCIAL_TITLE: "Följ oss via sociala medier",
  },
  ACCOUNT: {
    HEADING: "Mitt konto",
    USER: "Användaruppgifter",
    ADDRESS: "Adress",
    PAYMENT_INFO: "Betalningsinfo",
    Statistik: "Statistik",
    LAST_12_MONTHS: "Senaste 12 månaderna",
    USERNAME: "E-postadress",
    PASSWORD: "Lösenord",
    PASSWORD_REPEAT: "Upprepa lösenord",
    EDIT_ORDER_COPY_EMAIL: "Kopia orderbekräftelse",
    EDIT_ORDER_COPY_EMAIL_TITLE: "Lägg till e-post för kopia av orderbekräftelse",
    CUSTOMER_TYPE: "Kundtyp",
    SUBSCRIBE_TO_NEWSLETTER: "Få information om kampanjer och nya varumärken i sortimentet",
    REGISTER_CHECKOUT: "Skapa konto",

    SELECTED_ADDRESS: "Förvald Leveransadress och faktura-adress",
    OTHER_ADDRESS: "Alternativ adress",
    CHANGE_PASSWORD: "Ändra lösenord",
    ALCOHOL_CERTIFICATE: "Alkoholtillstånd",

    EDIT_USER: "Ändra användare",
    CREATE_ADDRESS: "Skapa adress",
    EDIT_ADDRESS: "Ändra adress",
    EDIT_ORG_NUMBER: "Ändra organisationsnummer",
    EDIT_CUSTOMER_TYPES: "Ändra kundtyp",
    REGISTRATION_CODE: "EV. Registreringskod",

    EDIT: "Ändra",
    ADD: "Lägg till",

    MORE_ALTERNATIVES: "Fler alternativ",

    SAVE: "Spara",
    BACK: "Tillbaka",
  },
  ALCOHOL_LICENSE: {
    TITLE: "Alkoholtillstånd",
    HEADING: "Ladda upp fler tillstånd",
    ADDRESS_LABEL: "Tillståndet gäller",
    ADDRESS_PLACEHOLDER: "Välj en adress",

    VALID_TO_LABEL: "Giltig till",

    STATUS_LABEL: "Status",

    FILENAME_LABEL: "Uppladdad fil",

    YEAR_PLACEHOLDER: "ÅÅÅÅ",
    MONTH_PLACEHOLDER: "MM",
    DAY_PLACEHOLDER: "DD",

    FILE_LABEL: "Ladda upp fil (PDF)",

    STATUS_LABEL: "Status",
    // ADDRESS_LABEL: "Leveransaadress",
    ADDRESS_LABEL: "Tillståndet gäller",

    BACK: "Tillbaka till mitt konto",
    SUBMIT: "Spara alkoholtillstånd",
    DELETE: "Radera alkoholtillstånd",
    DELETING: "Raderar...",

    PRODUCT_WARNING: "Alkoholtillstånd krävs",
    CREATE: "Skicka in här",
  },
  ALCOHOL_LICENSE_LIST: {
    HEADING: "Vi kontrollerar den fil du laddat upp",
    INFO: `Du kommer att få ett meddelande när vi har gått igenom och godkänt det alkoholtillstånd du nyligen laddade upp.
          <br />
          Processen brukar ta 1-2 dagar.`,
  },
  ORDER_HISTORY: {
    TITLE: "Orderhistorik",
  },
  WISHLIST: {
    TITLE: "Tidigare köpta varor",
    TITLE_NO_PREVIOUS_ORDERS: "Din framtida inköpslista: Mina produkter",
    Shoppinglist: "Smart inköpslista",
    favourites: "Favoriter",
    history: "Tidigare köpta varor",
    recommended: "Produktförslag",

    DEFAULT_DESCRIPTION:
      "Här listar vi en blandning av varor du handlat tidigare, valt som favorit och produktförslag baserat på dina inköp och din kundtyp (%customerType).",
    DESCRIPTION_NO_PREVIOUS_ORDERS:
      "Här listar vi en blandning av varor du handlat tidigare och valt som favorit. Tills dess du gjort ditt första köp visar vi varor som andra med kundtyp %customerType brukar handla. Vill du se hela sortimentet klickar du på Alla produkter.",
    FAVORITES_DESCRIPTION: "Här ser du alla favorier, du kan välja nya favoriter på produktsidor eller i kassan.",
    CONTRACTED_DESCRIPTION: "Här ser du dina avtalade produkter.",
    PREVIOUSLY_BOUGHT_DESCRIPTION: "Här ser du produkter du tidigare handlat.",
    RECOMMENDED_DESCRIPTION: "Här listar vi produktförslag baserat på din kundtyp (%customerType).",
  },
  REGISTER: {
    TITLE: "Skapa konto",
    LOGGINGIN: "Inloggning",
    PROCEED: "Gå vidare",
    SUBMIT: "Skapa konto och aktivera rabatt",
    SUBMIT_WITH_REGISTER_CODE: "Börja handla",
    CANCEL: "Avbryt",

    STEP_1: "Verksamhet",
    STEP_2: "Användare",
    STEP_3: "Adress",
    STEP_4: "Bekräfta",

    STEP_4_TITLE: "Välj ditt pris",
    STEP_4_TITLE_WITH_REGISTRATION_CODE: "Allt klart och redo!",
    STEP_4_BODY:
      "Vad är viktigast för er? En flexibel låg fri fraktgräns eller ett riktigt spetsat pris? Eller någonting i landet lagom? Du får högre fast rabatt om du kan beställa mer åt gången. Här kan du välja vilken fri fraktgräns (och rabatt) som passar er verksamhet.",
    STEP_4_BODY_WITH_REGISTRATION_CODE:
      "Ok. Inga fler frågor nu. Vi lovar! Nu är allt klart och du är redo att köra igång. Din ifyllda registreringskod har gjort att vi nu kopplat ditt konto till en prisgrupp med inlagda rabatter över hela sortimentet.",

    TYPE_TITLE: "Vilken typ av verksamhet",
    TYPE_DESCRIPTION:
      "Välj det som passar er bäst, så försöker vi anpassa vår sida så att ni ser mer relevanta produkter för er verksamhet.",
    SUB_TYPES_TITLE: "Vad för typ av %customer_type",
    PRODUCT_PREFERENCES_TITLE: "Gillar du något särskilt?",
    YOU_CAN_CHOOSE_MULTIPLE: "(Du kan välja flera alternativ)",
    SELECT_TYPE: "Vilket val passar in på er verksamhet?",
    SKIP: "Hoppa över",
    CUSTOMER_TYPE_INFO:
      "Vår nya webbshop är rätt smart för den kan föreslå schyssta produkter baserat på vad andra med samma typ av verksamhet som er brukar handla. Om du har en sekund över får du därför gärna välja nedan vad som passar in bäst på er. Du kan ändra detta senare under Mitt Konto.",
    CUSTOMER_TYPE_SUBMIT: "Tack för att du tog dig tid!",
    TERMS_MESSAGE: "Jag godkänner",
    PRIVACY_POLICY: "sekretesspolicy",
    AND: "och",
    TERMS_AND_CONDITIONS: "användarvillkor",
    HOW_DID_YOU_FIND_OUTOFHOME: "Hur hittade du outofhome.se?",
    HAS_ALCOHOL_LICENSE:
      "Vi har alkoholtillstånd på denna adress<br /><i>Du kan ladda upp detta senare under Mitt Konto</i>",
    COMPANY: "Företagsnamn (Ex. Lisas café)",
  },
  LOGIN: {
    SUBMIT: "Logga in",
  },
  ADDRESS: {
    ADDRESS: "Adress",
    COMPANY: "Företagsnamn",
    ORG_NUMBER: "Organisationsnummer",
    FIRSTNAME: "Förnamn",
    LASTNAME: "Efternamn",
    STREET: "Adress 1",
    STREET_2: "Leveransinstruktion",
    EMAIL: "E-mail",
    TELEPHONE: "Telefon",
    POSTCODE: "Postnummer",
    CITY: "Stad",

    IS_DEFAULT_BILLING_ADDRESS: "Förvald faktureringsadress",
    IS_DEFAULT_SHIPPING_ADDRESS: "Förvald leveransadress",

    DELETE: "Radera adress",

    REFERRER_CODE: "Ange sälj-ID",
  },
  FILTER: {
    TITLE_EMPTY: "Hela sortimentet",
    TITLE_SEARCH: 'Sökning "%search"',
    TITLE_CUSTOMER_TYPE_HEAD: "Mest köpta",
    TITLE_CUSTOMER_TYPE_TAIL: "för %customerType",
    TITLE_RELATED_PRODUCTS: "Relaterade produkter till %product",
    TITLE_BRAND: "från %brand",
    TITLE_BRAND_ALONE: "Sortiment från %brand",
    TITLE_MANUFACTURER_ALONE: "Hela sortimentet från %manufacturer",

    DESCRIPTION_FILTERED:
      "Ett stort urval %str för café, restaurang, hotell och andra storkök och verksamheter. Med effektiv e-handel skapar vi konkurrens och transparens inom foodservice. Snabba leveranser och schyssta priser.",

    DESCRIPTION:
      "Ett stort urval för café, restaurang, hotell och andra storkök och verksamheter. Med effektiv e-handel skapar vi konkurrens och transparens inom foodservice. Snabba leveranser och schyssta priser.",

    ACTIVATE_FILTER: "Visa produkter",
    PRODUCT_TYPE: "Produkttyp",
    BRANDS: "Varumärken",
    RESET: "Nollställ",
    RESET_FILTER: "Nollställ filter",
    NO_PRODUCTS_FOUND: "Inga produkter hittades.",

    SORT_BY: "Sortera efter",
  },
  FILE_UPLOAD: {
    DROP_FILE: "Släpp fil",
    DROP_OR: "Släpp eller",
    BROWSE: "Bläddra",
    DESKTOP_TITLE: "Släpp eller <span>bläddra</span>",
    DESKTOP_TITLE__ACTIVE: "Släpp fil",
    TOUCH_TITLE: "Klicka här för att ladda upp",
  },
  PRODUCT_TIP_FORM: {
    TITLE: "Kanske saknas produkten i vårt sortiment?",

    PRODUCT_NAME: "Produktnamn",
    SKU: "Artikelnummer",
    BRAND: "Varumärke",

    SUBMIT: "Tipsa oss!",
  },
  STOCK: {
    STATUS_2: "%stock i lager – skickas tidigast %date",
    STATUS_3: "%stock i lager – utgående artikel",

    STATUS_2_TITLE: "Temporärt lågt lagersaldo",
    STATUS_2_MESSAGE:
      'Du har lagt till mer än vad vi har i lager av en produkt. Vill du ha leverans direkt? Justera antalet så att det motsvarar vårt nuvarande lagersaldo. <p><b style="color: #ea413e">OBS: Lägger du en order som överstiger vårt lagersaldo, så kommer hela ordern skickas först när vi har tillräckligt i lager.</b></p>',

    STATUS_3_TITLE: "Utgående artikel",
    STATUS_3_MESSAGE:
      'Artikeln kommer att utgå från vårt sortiment och du kan därför inte beställa mer än vad som finns i lager. Har du frågor så är du välkommen att <a href="/kundservice">kontakta oss</a>.',

    STATUS_8_TITLE: "Restnoterad hos leverantör",
    STATUS_8_MESSAGE:
      "Vi har %stock kvar i lager men vi har fått information från %brand om att den är restnoterad. Lägger du beställningen över vårt lagersaldo kommer den invänta dagen då vi återigen kan beställa produkten från %brand. Vill du ha leveransen direkt rekommenderar vi att beställa enligt vårt befintliga lagersaldo.",
    STATUS_8_OUTOFSTOCK_MESSAGE:
      "Vi har slut i lager och produkten är restnoterad av %brand. Beställningar på produkten kommer vänta på att artikeln kommer tillbaka hos %brand så att vi kan fylla på.",

    STATUS_8_BOTTOM_DATE: "%stock i lager - förväntas åter %date (om det finns bekräftat leveransdatum på inköpsorder)",
    STATUS_8_BOTTOM: "%stock i lager - restnoterad tills vidare (om vi saknar inköpsorder och bekräftat leveransdatum)",
    STATUS_8_OUTOFSTOCK_BOTTOM_DATE:
      "Restnoterad av %brand - förväntas åter %date (om det finns bekräftat leveransdatum på inköpsorder)",
    STATUS_8_OUTOFSTOCK_BOTTOM:
      "Restnoterad av %brand - inväntar datum för nästa inleverans (om vi saknar inköpsorder och bekräftat leveransdatum)",

    STATUS_8_PRODUCT_CARD: "%stock i lager – restnoterad av leverantör",
    IN_STOCK: "I lager",
    OUT_OF_STOCK: "Slut i lager – skickas tidigast %date",
    OUT_OF_STOCK_TODAY: "Slut i lager - bör inlevereras idag",
    BACKORDER: "Restnoterad hos leverantör",
    NEXT_DELIVERY: "%stock i lager – skickas tidigast %date",
    NEXT_DELIVERY_TODAY: "%stock i lager – bör inlevereras idag",
  },
  SORT: {
    POPULAR: "Bästsäljare",
    NAME: "A-Ö",
    BRAND: "Varumärke",
    CATEGORY: "Kategori",
    COMPARE_PRICE: "Jämförelsepris",
  },
  SHIPPING_METHOD: {
    freeshipping_freeshipping: "Fri frakt",
    amtable_amtable3: "Leverans",
    gordon_standard: "Gordon Delivery",
    bring_standard: "Bring Last Mile",
  },
  AMTABLES: {
    OK: "Utifrån leveransdagar på ditt postnummer har vi beräknat när du kan få din leverans. Uppdaterad leveransdag skickas i leveransbekräftelse när ordern lämnat vårt lager.",
    NO_TIMETABLE:
      "Ditt postnummer trafikeras inte i ordinarie distribution. Vi skall göra allt vi kan för att leverera din order men kan inte lova exakt leveransdag. Normalt tar våra leveranser 1-3 dagar från att de har skickats.",
    NO_POSTCODE:
      "Vi saknar information om leveransdagar till ditt postnummer. Postnumret saknas helt från vår lista och det kan bero på att det är nytt, att vi missat det eller att du skrivit fel. Om du är säker på att du skrivit rätt kan du lägga ordern. Vi skall göra allt vi kan för att leverera din order. Normalt tar våra leveranser 1-3 dagar från att de har skickats.",
    BELOW_WEIGHT: "Företagspaket, 8-17. Leverans 1-3 dagar från att det har skickats.",
    CARRIER: "Transportör",
    TIME_WINDOW_RANGE: "Leveranstid på postnummer",
  },
  SUPPLIER_INQUIRY: {
    HEADING: "Bli leverantör",
    TITLE: "Bli leverantör",
    SUBMIT: "Skicka",

    COMPANY: "Företag *",
    CONTACT_PERSON: "Kontaktperson *",
    EMAIL: "E-postadress *",
    PHONE: "Telefonnummer *",
    BRANDS: "Varumärken *",
    CATEGORY: "Produktkategori *",
    NUMBER_OF_SELLERS: "Antal säljare",
    WHOLESALERS_TODAY: "Grossister idag",
    NUMBER_OF_SKUS_FOODSERVICE: "Antal artikelnummer foodservice",
    NUMBER_OF_SKUS_DVH: "Antal artikelnummer dvh",
    OFFICE_LOCATION: "Huvudkontor ligger i region",
  },
  FOOTER_LINKS: {
    FAQ: "Vanliga frågor",
    CAMPAIGNS: "Kampanjer",
    OUT_OF_DATE: "Out of Date",
    INFORMATION: "Information",
    ABOUT_US: "Om oss",
    CUSTOMER_SERVICE: "Kundservice",
    NEWS: "Nyheter",
    REGISTER: "Bli kund",
    RESET_PASSWORD: "Återställ lösenord",
    CARBON_OFFSETS: "Klimatkompensation",
    FORGOT_PASSWORD: "Återställ lösenord",
    CLIMATE_COMPENSATION: "Klimatkompenserad transport",
    WORK_FOR_US: "Jobba hos oss",

    STOCK: "Sortiment",
    OUR_SUPPLIERS: "Våra leverantörer",
    BECOME_SUPPLIER: "Bli leverantör",
    ALL_PRODUCTS: "Alla produkter",

    WHOLESALER_FOR: "Grossist för",
    RESTAURANT: "Restaurang",
    CAFE: "Cafe",
    HOTEL: "Hotell & konferens",
    OFFICE: "Kontor/arbetsplats",
    GYM: "Gym/träning/förening",
    GROCERY_STORE: "Dagligvaruhandel",
    PRODUCTION: "Livsmedelsproduktion",
    PUBLIC_SECTOR: "Offentlig verksamhet",
    CONVENIENCE_STORES: "Servicehandel",
    RETAIL_STORE: "Detaljhandel",
    WHOLESALE: "Grossist",

    OPEN_TRADE: "Öppen handel",
    OPEN_TRADE_1: "Alternativ livsmedelsgrossist",
    OPEN_TRADE_2: "Rikstäckande grossist",
    OPEN_TRADE_3: "Referenskunder",
    OPEN_TRADE_4: "Välj ditt pris",

    CUSTOMERS: "Referenskunder",
    CUSTOMERS_1: "Bulldozer reklambyrå",
    CUSTOMERS_2: "Konditori Sturekatten",
    CUSTOMERS_3: "Café Feel",
    CUSTOMERS_4: "Villa Belparc",
    CUSTOMERS_5: "Thörnströms Kök",
    CUSTOMERS_7: "Delsjö Golfkrog",
    CUSTOMERS_8: "Lejonet & Björnen",
    CUSTOMERS_9: "Nordells Konditori",
    CUSTOMERS_10: "Norges Hus",
    CUSTOMERS_11: "Skolfood",
  },
  SOCIAL: {
    FACEBOOK_URL: "https://www.facebook.com/outofhome.se",
    INSTAGRAM_URL: "https://www.instagram.com/outofhome_ab/",
    TWITTER_URL: "https://twitter.com/outofhome_AB",
    LINKEDIN_URL: "https://www.linkedin.com/company/outofhome-ab/",
  },
  OTHER: {
    BACK: "Tillbaka",
    OPEN: "Öppna",
    DISPLAY_ALL: "Visa alla",
    FILTER: "Kategorier",
    SORT: "Sortera",
    TO_TOP: "Till toppen",
  },
  ROUTES: {
    FAQ: "faq-vanliga-fragor",
    CAMPAIGNS: "p/?certified=Kampanjer",
    OUT_OF_DATE: "p/?certified=Kort datum",
    SUPPLIER_INQUIRY: "bli-leverantor",
    REGISTER: "registrera",
    BRANDS: "varumarken",
    NEWS: "nyheter",
    NEWSPOST: "nyheter",
    FORGOT_PASSWORD: "glomtlosenord",
    MY_PRODUCTS: "inkopslista",
    TARGETED_PRODUCTS: "produktlista",
    CONTRACT: "inkopslista/?shoppinglist=Avtalade%20produkter",
    ORDER_HISTORY: "orderhistorik",
    ACCOUNT: "konto",
    DISCOUNTS: "mina-rabatter",
    USER_DATA: "uppgifter",
    ALCOHOL_LICENSE: "alkoholtillstand",
    ORG_NUMBER: "organisationsnummer",
    ORDER_COPY_EMAIL: "kopia-orderbekraftelse",
    CUSTOMER_TYPE: "kundtyp",
    ADDRESS: "adress",
    LOGIN: "#logga-in",
    LOGOUT: "logga-ut",
    ABOUT_US: "om-oss",
    EMPLOYEES: "om-oss/medarbetare",
    CUSTOMER_SERVICE: "kundservice",
    CART: "checkout/varukorg",
    RECOMMENDED: "rekommenderade",
    PRODUCT: "produkt",
    CHECKOUT_SUCCESS: "checkout/success", // kopplas även i magento admin för dibs callback

    // cms pages
    TERMS: "anvandarvillkor",
    PRIVACY: "sekretesspolicy",
    BECOME_SUPPLIER: "bli-leverantor",
    CLIMATE_COMPENSATION: "Klimatkompensation",

    // restaurang
    CUSTOMER_SEGMENT_1: "/restaurang",
    // cafe
    CUSTOMER_SEGMENT_2: "/cafe",
    // hotell & konferens
    CUSTOMER_SEGMENT_4: "/hotell-och-konferens",
    // gym/träning/förening
    CUSTOMER_SEGMENT_6: "/gym-traning-forening",
    // dagligvaruhandel
    CUSTOMER_SEGMENT_7: "/dagligvaruhandel",
    // kontor/arbetsplats
    CUSTOMER_SEGMENT_8: "/kontor-och-arbetsplats",
    // produktion
    CUSTOMER_SEGMENT_9: "/produktion",
    // offentlig verksamhet
    CUSTOMER_SEGMENT_10: "/offentlig-verksamhet",
    // servicehandel
    CUSTOMER_SEGMENT_11: "/servicehandel",
    // detaljhandel
    CUSTOMER_SEGMENT_12: "/detaljhandel",
    // grossist
    CUSTOMER_SEGMENT_13: "/grossist",

    // öppen handel
    OPEN_TRADE_1: "/grossist-livsmedel",
    OPEN_TRADE_2: "/matgrossist",
    OPEN_TRADE_3: "/kunder",
    OPEN_TRADE_4: "/valj-ditt-pris",

    // customers
    CUSTOMERS_1: "/kunder/bulldozer",
    CUSTOMERS_2: "/kunder/konditori-sturekatten",
    CUSTOMERS_3: "/kunder/cafe-feel",
    CUSTOMERS_4: "/kunder/villa-belparc",
    CUSTOMERS_5: "/kunder/thornstroms-kok",
    CUSTOMERS_7: "/kunder/delsjo-golfkrog",
    CUSTOMERS_8: "/kunder/lejonet-och-bjornen",
    CUSTOMERS_9: "/kunder/nordellskonditori",
    CUSTOMERS_10: "/kunder/norges-hus",
    CUSTOMERS_11: "/kunder/skolfood",
  },
  FORM_FIELDS: {
    email: "E-post",
    password: "Lösenord",
    password_repeat: "Upprepning av lösenord",
    firstname: "Förnamn",
    lastname: "Efternamn",
    telephone: "Telefonnummer",
    street_2: "Leveransinstruktion",
    street2: "Leveransinstruktion",
    street: {
      1: "Leveransinstruktion",
    },
  },
  BOOLEAN_VALUES: {
    WITH: "med",
    WITHOUT: "utan",
    YES: "Ja",
    NO: "Nej",
  },
  STRIPE: {
    PROCESSING: "Laddar...",
    CARD_NUMBER: "Kortnummer",
    "MM/YY": "MM/YY",
    CVC: "CVC",
  },
  VALIDATION: {
    REQUIRED: "Vänligen fyll i detta fält",
    LENGTH_GT: "Minst %lengthGt tecken",
    ARRAY_LENGTH_GTE: "Minst %arrayLengthGt val",
    LENGTH_LTE: "Max %lengthLte tecken",
    IS_PASSWORD: "Minst 7 tecken",
    MATCH: "måste matcha",
    PHONE: "Ogiltigt telefonnummer",
    EMAIL: "Ogiltig epost",
    EMAIL_NOT_AVAILABLE: "E-postadressen används redan",
    NUMERIC: "Ej numeriskt värde",
    EMAIL_NOT_VALID: "E-postadressen finns redan",
    ORG_NR_NOT_VALID: "Endast siffror och bindestreck",
    POSTCODE_NOT_VALID: "Ogiltigt postnummer",
  },
  STATUS_CODE: {
    updateCustomerAddress: {
      errorInvalidAddress: "Felaktigt format på adress",
    },
    loginCustomer: {
      errorInvalidEmailOrPassword: "Ogiltig e-postadress eller lösenord",
      errorUserNotConfirmed: "Vänligen bekräfta ditt konto via e-post",
    },
    setQuotePaymentMethod: {
      errorCountryNotAllowed: "Detta betalsättet finns inte tillgängligt i ditt land",
      errorNotAvailable: "Det gick inte att välja betalsättet",
      invalidSsn: "Vi hittade tyvärr inget företag på ert organisationsnummer",
    },
    errorPaymentNotAvailable: "Betalsättet är inte tillgängligt. Vänligen kontrollera ditt val.",

    /* client */
    UNKNOWN: "Ett okänt fel har uppstått. Vänligen ladda om sidan och försök igen.",
    INVALID_CODE: "Felaktig rabattkod.",
    NETWORK_ERROR:
      "Anropet avbröts. Vänligen verifiera att du har en aktiv internetanslutning eller försök igen om en stund.",

    REGISTER_ERROR: "Ett fel inträffade vid registreringen. Vänligen försök igen senare eller kontakta vår kundtjänst.",

    /* success messages */
    "-1500": "Offertförfrågan skickad!", // Offert
    "-500": "Tack! Vi återkommer snart", // Empty search form
    "-1501": "Stort tack!", // Product tip
    "-1502": "Förfrågan skickad!", // Supplier inquiry sent
    "-1503": "Förfrågan kunde inte skickas. Vänligen försök igen senare eller kontakta vår kundtjänst.", // Supplier inquiry send error
    "-2000": "Adressen är nu uppdaterad!",
    "-2001": "Adressen är nu skapad!",
    "-2002": "Adressen är nu raderad!",

    "-2100": "Användaruppgifterna är nu uppdaterade!",

    /* forms */
    "-6100": "Välj det som passar bäst in på er",
    "-6101": "Välj en eller flera alternativ som passar in på er verksamhet",

    /* alcohol */
    "-7000": "Licensen är nu raderad",

    /* product */
    1000: "Produkten kunde inte hittas.",

    /* cart */
    2000: "Produkten finns inte i lager.",
    2001: "Önskat antal är inte tillgängligt.",
    2002: "Produkten saknar attributval.",
    2003: "Produkten kunde inte läggas till i kundvagnen.",
    2004: "Vänligen ange produkt och antal.",
    2005: "Vänligen ange produkt.",
    2006: "Produkten kunde inte hittas.",
    2007: "Produkten finns inte längre i kundvagnen.",
    2008: "Produkten kunde inte läggas till i kundvagnen.",
    2009: "Produkten finns inte längre i kundvagnen.",
    2010: "Högsta tillåtna antal för begärd produkt är överskridet.",
    2011: "Något gick fel. Vänligen kontakta kundtjänst.",
    2010: "Valt antal finns inte i lager",
    20010: "Valt antal finns inte i lager",

    /* customer */
    3000: "Du är inte inloggad. Vänligen logga in och försök igen.",
    3001: "Registrering är för närvarande avstängt.",
    3002: "E‑postadress saknas.",
    3003: "Ett okänt fel har uppstått. Vänligen kontakta kundtjänst.",
    3004: "Kunduppgifterna kunde ej valideras.",
    3005: "Det finns redan en användarkonto för e‑postadressen.",
    3006: "Ogiltig e-postadress eller lösenord.",
    3007: "Adressuppgifterna kunde ej valideras.",
    3008: "Ett okänt fel har uppstått. Vänligen kontakta kundtjänst.",
    3011: "Ingen kund med denna e-postadress hittades.",

    /* checkout */
    4000: "Felaktigt betalsätt.",
    4001: "Felaktigt betalsätt.",
    4002: "Felaktigt leveranssätt.",
    4003: "Felaktigt leveranssätt.",
    4004: "Kundvagnen kunde inte hittas.",
    4005: "Adressuppgifterna innehåller ett fel.",
    4006: "Adressuppgifterna kunde inte verifieras.",
    4007: "Ett okänt fel uppstod.",
    4008: "Din kundvagn är tom.",
    4009: "Leveransadressen kunde inte verifieras.",
    4010: "Vänligen välj leveranssätt.",
    4011: "Faktureringsadressen kunde inte verifieras.",
    4012: "Vänligen välj betalsätt.",
    4013: "Vi har för närvarande problem med den valda betalmetoden. Vänligen försök med en annan.",
    4014: "Betalningsuppgifterna kunde inte verifieras.",
    4015: "Ett okänt fel uppstod.",
    4016: "Det valda betalsättet är inte tillgängligt.",
    4017: "Obligatoriska villkor är inte godkända.",
    4018: "Ett fel har uppstått. Vänligen kontakta kundtjänst.",

    /* DIBS */
    6000: "Ordernumret saknas.",
    6001: "Betalningsuppgifterna kunde ej verifieras.",
    6002: "Ordern kan inte hittas.",

    /* FORMS */
    1001: "Ett fel uppstod",

    5000: "Fel epost och/eller lösenord",

    /* Klarna */
    11000: "Ett okänt fel har uppstått. Vänligen försök igen, eller prova ett annat betalsätt.",
    11001: "Personnummer saknas.",
    11002: "Ofullständiga uppgifter om betalsätt.",
    11003: "Det valda betalsättet är ej aktivt.",
    11004: "Betalning nekades. Vänligen försök igen, eller prova ett annat betalsätt.",

    /* Walley */
    12008: "Org. nummer för Walley saknas",
    12001: "Ogiltigt org. nummer",
    12003: "Betalning nekad av Walley. Vänligen använd ett annat betalsätt eller kontakta Walleys kundtjänst.",
    12002: "Betalning nekad av Walley. Vänligen använd ett annat betalsätt eller kontakta Walleys kundtjänst.",
    12007: "Felaktigt org. nummer för det angivna företagsnamnet",
    12015: "Leveransinstruktionen är för lång. Max 50 tecken.",

    /* Gordon delivery */
    301: "Postnumret måste innehålla 5 siffror",
    302: "Invalid gordon time range format (3:12-23:59, optional leading zero)",

    /* Special klarna, TODO: Move to module */
    "-3201":
      "Mobiltelefonnumret du angivit har inte rätt format. Kontrollera att alla siffror stämmer och försök igen med följande format: 07NN NN NN NN. Om du fortfarande får detta meddelande, välj ett annat betalsätt för att genomföra ditt köp.",

    // register in checkout
    "-7500": "E-postadressen för kontot är redan registerad.",

    FORGOT_PASSWORD: "Vi har skickat ett mail till din angivna mailadress",
    FORGOT_PASSWORD_SUCCESS: "Ditt lösenord är nu återställt",
    FORGOT_PASSWORD_UNKNOWN_EMAIL: "Det finns inget konto med denna e-postadressen",

    RESET_PASSWORD: {
      success: "Ditt lösenord har nu uppdaterats!",
      customerNotFound: "Kontot hittades inte.",
      tooManyRequests: "För många anrop. Vänligen vänta en stund först.",
      linkHasExpired: "Länken från mailet har löpt ut. Vänligen Välj att återställa lösenord igen.",
      invalidEmail: "Felaktig e-postadress.",
      error: "Ett fel inträffade. Vänligen kontakta oss på kundservice@outofhome.se",
    },

    INVOICE_REMINDER:
      "Vi upptäckte precis att ni har minst en obetald faktura. Om fakturan inte kommit fram som den skulle går den att ladda ner från din orderhistorik. Vår policy är att inte skicka iväg leveranser förrän detta reglerats. Behöver du ha din leverans omgående vänligen maila inbetalningskvitto till ekonomi@outofhome.se.",

    /* WFM customer service form */
    "-8000":
      "Tack! Vi mottog hela din rapport, precis så som du skickade den. Varenda bokstav och siffra. Vårt lager noterar att felplocket har skett och märker upp artiklarna tydligare. Vi återkopplar inom kort! / outofhome-teamet",
    WFM__ERROR: "Ett fel inträffade. Ni kan nå oss direkt på kundservice@outofhome.se",
    WFM__ERRORNOTLOGGEDIN: "Du har blivit utloggad. Vänligen logga in igen",
    WFM__ERRORORDERNOTFOUND:
      "Ingen order hittades att koppla rapporten till, vänligen försök igen eller kontakta oss på kundservice@outofhome.se",

    WFM_FAULTY_DELIVERY__SUCCESS:
      "Tack! Vi mottog hela din rapport, precis så som du skickade den. Varenda bokstav och siffra. Vårt lager noterar att felplocket har skett och märker upp artiklarna tydligare. Vi återkopplar inom kort! / outofhome-teamet",
    WFM_DELIVERY_DAMAGE__SUCCESS:
      "Tack! Din rapport kom fram helt oskadd till vår server. Vårt lager och vår transportör får informationen som feedback och vi återkopplar inom kort. / outofhome-teamet",
    WFM_RETURN_ITEM__SUCCESS: "Tack! Vi har mottagit din reklamation och återkopplar inom kort. / outofhome-teamet",
    WFM_SHORT_DATE__SUCCESS:
      "Tack! Din helt färska rapport har nu skickats till oss. Vi återkopplar inom kort. / outofhome-teamet",
    WFM_INVOICE_ERROR__SUCCESS:
      "Om avtalskund/Visma-faktura: På din orderhistorik kan du se och ladda ner alla utställda fakturor.",

    WFM_CONTACT_US__SUCCESS: "Tack - vi hör av oss inom kort! / outofhome-teamet",

    USER_NOT_LOGGED_IN_ANYMORE: "Du har blivit utloggad. Vänligen logga in igen",

    errorNoShippingMethod: "Inget fraktsätt valt",

    DISCOUNT_CODE_FAILURE: "Felaktig kod",
    DISCOUNT_CODE_APPLIED: "Koden är nu aktiverad",

    CHECKOUT_CREATE_ACCOUNT_ERROR: "Ett fel inträffade. Vänligen försök igen senare.",
    INVALID_SSN: "Ogiltigt organisationsnummer",
    CHECKOUT_SHIPPING_METHOD_ERROR: "Ett fel inträffade. Vänligen ladda om sidan",

    CHECKOUT_ERROR: "Ett fel inträffade",
  },
};
